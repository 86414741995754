
















import Vue from 'vue'
export default Vue.extend({
  name: 'sls-admin-site-alert',
})
